export const menuItems = [
    {
        id: 1,
        label: "Menu",
        i18n: "Menu",
        isTitle: true
    },
    {
        id: 2,
        label: "Dashboard",
        i18n: "Dashboard",
        icon: "ri-dashboard-line",
        link: '/dashboard'
    },
    {
        id: 3,
        label: "Languages",
        i18n: "Languages",
        icon: "ri-sort-desc",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 4,
                label: 'My languages',
                i18n: 'MyLanguages',
                link: '/my-languages'
            },
            {
                id: 5,
                label: 'All languages',
                i18n: 'AllLanguages',
                link: '/languages'
            }
        ]
    },
    {
        id: 23,
        label: "Account",
        i18n: "Account",
        icon: "ri-shield-user-line",
        link: '/account'
    },
    {
        id: 24,
        label: "Subscriptions",
        i18n: "Subscriptions",
        icon: "ri-shield-user-line",
        link: '/subscriptions'
    },
    {
        id: 25,
        label: "Notifications",
        i18n: "Notifications",
        icon: "ri-notification-2-line",
        link: '/notifications'
    },
];
