=<template>
<!-- Footer Start -->
<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                &copy; {{ new Date().getFullYear()}} <a :href="absoluteUrl()">{{$store.state.appName}}</a>
            </div>
        </div>
    </div>
</footer>
<!-- end Footer -->
</template>

<script>
/**
 * Footer Component
 */
export default {}
</script>


