<template>
  <b-nav-item-dropdown variant="white" class="d-none d-lg-inline-block" right toggle-class="header-item">
    <template v-slot:button-content>
        {{text}}
    </template>
    <b-dropdown-item class="notify-item" v-for="(entry, i) in languages" :key="`Lang${i}`" :value="entry" @click="setLanguage(entry.language, entry.title, entry.flag)" :link-class="{'active': entry.language === current_language}">
        <span class="align-middle">{{ entry.title }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
export default {
  data(){
    return{
      languages: [
        {
            language: "en",
            title: "English",
        },
      ],
      current_language: this.$i18n.locale,
      text: null,
      flag: null,
      value: null,
    }
  },

  methods: {
    updateLocale(locale) {
      this.$i18n.locale = locale;
    },

    setLanguage(locale, country, flag) {
        this.$i18n.locale = locale;
        this.current_language = locale;
        this.text = country;
        this.flag = flag;
    },
  },
  mounted() {
    this.value = this.languages.find((x) => x.language === this.$i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;
  },
}
</script>
